import { I18nKeys, I18nsEnum, I18nsMap } from '@/constants/i18n'
import { i18n } from '@lingui/core'
import { en, zh } from 'make-plural/plurals'
import { envIsClient } from '@/helper/env'
import { baseCommonStore } from '@/store/common'

export const defaultLocale = I18nsEnum['en-US']

i18n.loadLocaleData({
  [I18nsEnum['en-US']]: { plurals: en },
  [I18nsEnum['zh-CN']]: { plurals: zh },
  [I18nsEnum['zh-HK']]: { plurals: zh },
})

/**
 * We do a dynamic import of just the catalog that we need
 * @param locale any locale string
 */
export async function dynamicActivate(locale: string) {
  const { messages } = await import(`../locales/${locale}/messages.ts`)
  i18n.load(locale, messages)
  i18n.activate(locale)
}

const locales = Object.keys(I18nsMap)
export const localeDefault = I18nsEnum['en-US']

export function extractLocale(url) {
  const urlPaths = url.split('/')
  let locale
  let urlWithoutLocale
  const firstPath = urlPaths[1]
  const hasLang = locales.includes(firstPath)
  if (hasLang) {
    locale = firstPath
    urlWithoutLocale = `/${urlPaths.slice(2).join('/')}`
  } else {
    locale = null
    urlWithoutLocale = url
  }
  return { locale, urlWithoutLocale }
}

/** 判断当前语言是否为英文 */
export function isEn() {
  return i18n.locale === I18nsEnum['en-US']
}
/** 判断当前语言是否为中文 */
export function isCn() {
  return i18n.locale === I18nsEnum['zh-CN']
}

export function setLocale(lang: I18nsEnum) {
  if (envIsClient) {
    const _lang = lang === I18nsEnum['en-US'] ? '' : `/${lang}`
    const { setLocale: _setLocale } = baseCommonStore.getState()
    _setLocale(lang)
    const path = extractLocale(location.pathname + location.search).urlWithoutLocale
    document.location.assign(`//${location.host}${_lang}${path}`)
  }
}

export const removeLocale = (urlString?: string) => {
  if (!urlString) return
  // 外部路由不考虑处理多语言问题
  if (/^http/.test(urlString)) {
    return urlString.charAt(0) === '/' ? urlString : `/${urlString}`
  }
  let removed = urlString
  I18nKeys.forEach(k => {
    if (urlString.includes(k)) {
      const localeType = urlString[0] === '/' ? RegExp(`^/${k}`, 'i') : RegExp(`^${k}`, 'i')
      removed = urlString.replace(localeType, '')
    }
  })
  return removed.charAt(0) === '/' ? removed : `/${removed}`
}
